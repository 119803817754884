import styled from 'styled-components';
import { respondTo } from '../../utils/breakpoints';

export const Text = styled.p`
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.uiDarkBlue};
  line-height: 150%;


  &.center {
  
    text-align: center;
  }

  /* Media query for computer screens */
  @media (min-width: 1024px) {
    &.left {
      width: 50%;
    }
  }
  &.big {
    font-size: 24px;
  }
  &.white {
    color: ${(props) => props.theme.colors.uiWhite};
  }
`;

export const PageHeader = styled.h1`
  font-family: 'Montserrat';
  font-style: normal;
  text-transform: none;
  font-size: 48px;
  letter-spacing: -0.02em;
  font-weight: 700;
  line-height: 3.6875rem;
  color: ${(props) => props.theme.colors.uiWhite};
  margin-bottom: 1rem;
`;

export const SectionHeader = styled.h2`
  &.no-margin {
    margin-top: 0px !important;
  }
  font-family: 'Montserrat';
  font-style: normal;
  text-transform: none;
  font-size: 22px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.uiBlack} !important;
  b,
  strong {
    color: ${(props) => props.theme.colors.uiDarkBlue};
  }

  ${respondTo.m`
    font-size:2rem;
  `}
`;

export const SubHeader = styled.h3`
  font-family: 'Montserrat';
  font-style: normal;
  text-transform: none;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.25rem;
  letter-spacing: -0.02em;
  text-align: left;
`;


export const Header2 = styled.h2`

 @media (max-width: 800px) {
    &.partnerMobile {
      text-align: center !important;
    }

    &.override-text-align {
      font-size: 30px !important;
    } 
  }
  color: var(--dark, #2C3E50);
  font-family: 'Montserrat';
  text-transform: none;
  text-align: left;
  font-size: 36px;
  margin-top: 0px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 54px */
  margin-bottom: 0px;
  letter-spacing: -0.72px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  > strong {
    color: var(--base, #5FA5C5);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 36px */
    letter-spacing: -0.48px;
  }
  &.white {
    color: white;
  }

 
  
  &.override-text-align {
    ${respondTo.s`
      text-align: left !important;
      font-size: 24px;
    `}
    
  }
`

export const Header3 = styled.h3`
color: var(--ui-dark-blue, #0F5575);
text-align: center;

/* Monsterrat/24 | Semibold */
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -0.48px;
`